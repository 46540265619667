import React, { useState, useEffect } from 'react';
import queryString from 'query-string';
import {
  gql,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';

import SEO from 'components/Layout/SEO';

import getLastRevision from '@/helpers/getLastRevision';
import headerCredentials from '@/helpers/headerCredentials';

const withPreview = (Component, { queryPreview }) => {
  const Preview = (props) => {
    const { type } = props;
    const [data, setData] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const { preview, id } = queryString.parse(props.location.search);

    const options = {
      query: gql`
        ${queryPreview}
      `,
      variables: {
        id: Number(id),
      },
    };

    const isPreview = preview || props.location.pathname.includes('preview');

    useEffect(() => {
      if (!isPreview) return;

      const link = createHttpLink({
        uri: `${process.env.GATSBY_WP_URL}/graphql`,
        headers: {
          ...headerCredentials(),
        },
        credentials: 'include',
      });

      const client = new ApolloClient({
        cache: new InMemoryCache(),
        link,
      });

      const fetchPost = () => {
        client.query(options).then(({ data }) => {
          const postData = getLastRevision(data[type]);

          setData({
            ...props,
            ...{
              pageContext: {
                [type]: postData,
              },
            },
          });

          setLoaded(true);
        });
      };

      fetchPost();
    }, [isPreview]); // eslint-disable-line

    return !isPreview ? (
      <>
        {Object.values(props.pageContext)[0].seo && (
          <SEO seo={Object.values(props.pageContext)[0].seo} />
        )}
        <Component {...props} loaded={true} />
      </>
    ) : (
      <Component {...data} loaded={loaded} />
    );
  };

  return Preview;
};

export default withPreview;
