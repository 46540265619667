const getData = (data) => {
  if (
    !data.revisions ||
    !data.revisions.nodes ||
    data.revisions.nodes.length === 0
  )
    return data;

  const {
    revisions,
    ...page
  } = data;

  const lastRevision = Array.from(revisions.nodes)
    .reverse()
    .reduce((obj, revision) => ({
      ...obj,
      ...revision
    }), {
      ...page
    });

  if (!lastRevision) return data;

  return lastRevision;
};

export default getData;
