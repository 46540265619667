const commonPageData = (WpTag) => `
  id
  uri
  link
  content
  template {
    templateName
  }
  featuredImage {
    node {
      sourceUrl
      srcSet
    }
  }
  `;

module.exports.commonPageData = commonPageData;