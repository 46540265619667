import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import useSEO from 'hooks/graphql/useSocials';

const SEO = ({ seo = null, ogImage = null }) => {
  const seoDefaultWP = useSEO();

  const {
    site: { siteMetadata: seoDefaultSite },
    wp: { allSettings: settingsWP },
  } = useStaticQuery(graphql`
    query SEODefaultQuery {
      site {
        siteMetadata {
          title
          titleTemplate
          description
          siteUrl
          image
        }
      }
      wp {
        allSettings {
          generalSettingsDescription
          generalSettingsTitle
        }
      }
    }
  `);

  const {
    title: defaultTitle,
    titleTemplate,
    description: defaultDescription,
    url: siteUrl,
    image: defaultImage,
  } = seoDefaultSite;

  const seoDefault = {
    title:
      seo?.title ||
      seoDefaultWP.schema.siteName ||
      settingsWP.generalSettingsTitle ||
      defaultTitle,
    description:
      seo?.metaDesc ||
      settingsWP.generalSettingsDescription ||
      defaultDescription,
    image: seo?.image || `${siteUrl}${defaultImage}`,
    url: seo?.opengraphUrl || siteUrl,
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
      }}
      title={seoDefault.title}
      titleTemplate={titleTemplate}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: seo?.schema?.raw,
        },
      ]}
    >
      <meta name="description" content={seoDefault.description} />
      <meta name="image" content={seoDefault.image} />

      <meta property="og:url" content={seoDefault.url} />
      {seo?.opengraphType && (
        <meta property="og:type" content={seo.opengraphType} />
      )}
      <meta
        property="og:title"
        content={seo?.opengraphTitle || seoDefault.title}
      />
      {seoDefault.description && (
        <meta
          property="og:description"
          content={seo?.opengraphDescription || seoDefault.description}
        />
      )}

      {ogImage ? (
        <meta property="og:image" content={ogImage} />
      ) : (
        <meta property="og:image" content={seoDefault.image} />
      )}

      <meta name="twitter:card" content="summary_large_image" />
      {seo?.opengraphAuthor && (
        <meta name="twitter:creator" content={seo.opengraphAuthor} />
      )}
      <meta
        name="twitter:title"
        content={seo?.twitterTitle || seoDefault.title}
      />
      <meta
        name="twitter:description"
        content={seo?.twitterDescription || seoDefault.description}
      />

      {ogImage ? (
        <meta name="twitter:image" content={ogImage} />
      ) : (
        <meta
          name="twitter:image"
          // content={seo?.twitterImage || seoDefault.image}
          content={seoDefault.image}
        />
      )}

      <meta name="theme-color" content="#6f46fe" />
    </Helmet>
  );
};
export default SEO;
